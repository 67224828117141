<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-text>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref} from "@vue/composition-api";
// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale
// } from 'chart.js'
// import { Bar } from 'vue-chartjs'
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
export default {
  name: "BotPubChartDialog",
  setup(){
    const dialog = ref(false)
    const data = ref({
        labels: ['January', 'February', 'March'],
        datasets: [{ data: [40, 20, 12] }]
      })
    const options = ref({
        responsive: true
      })
    return{
      dialog,
      data, options,
    }
  }
}
</script>

<style scoped>

</style>
