<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <v-card>
              <v-card-title>Дата статы</v-card-title>
              <v-card-text>
                <v-date-picker v-model="filters.statsDate"></v-date-picker>
              </v-card-text>
            </v-card>
          </v-col> <!-- дата статы -->
          <v-col cols="3">
            <v-card>
              <v-card-title>Дата публикации</v-card-title>
              <v-card-text>
                <v-date-picker v-model="filters.dates"
                               label="Дата"
                               multiple
                               outlined
                               hide-details
                               clearable
                               range>
                </v-date-picker>
              </v-card-text>
            </v-card>
          </v-col> <!-- дата публикации -->
          <v-col cols="3">
            <v-card>
              <v-card-text>
                <ad-post-selector v-model="filters.ad_post_id" :hide-details="false"></ad-post-selector>
                <ad-channel-selector v-model="filters.ad_channel_id" :hide-details="false"></ad-channel-selector>
                <v-checkbox v-model="filters.from_bot" label="ИЗ ботов" hide-details></v-checkbox>
                <v-checkbox v-model="filters.from_channel" label="ИЗ каналов" hide-details></v-checkbox>
                <v-checkbox v-model="filters.to_bot" label="В бота" hide-details></v-checkbox>
                <v-checkbox v-model="filters.to_channel" label="В канал" hide-details></v-checkbox>
              </v-card-text>
            </v-card>
<!--            <v-select v-model="filters.post_id" :items="posts" item-value="id" item-text="name" label="Пост" outlined></v-select>-->
<!--            <v-select v-model="filters.ad_channel_id" :items="adChannels" item-value="id" item-text="name" label="Источник" outlined></v-select>-->
          </v-col> <!-- фильтры -->
          <v-col cols="3">
            <v-card>
              <v-card-text>
                <v-select v-model="filters.status" :items="statuses" label="Статус" clearable dense outlined ></v-select>
              </v-card-text>
            </v-card>
            <!--            <v-select v-model="filters.post_id" :items="posts" item-value="id" item-text="name" label="Пост" outlined></v-select>-->
            <!--            <v-select v-model="filters.ad_channel_id" :items="adChannels" item-value="id" item-text="name" label="Источник" outlined></v-select>-->
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-card>
          <v-data-table
            :items="publications"
            :headers="headers"
            :items-per-page="50"
            class="sticky-column-table"
            dense
          >
            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }"> <!-- подсказки для заголовков-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{h.text}}</span>
                </template>
                <span>{{h.hint}}</span>
              </v-tooltip>
            </template>
            <template v-slot:header.id="{ header }">
              <th :class="'sticky-column'">{{ header.text }}</th> <!-- приклеиваем левый столбец-->
            </template>
            <!--верхняя строка со средними и суммарными значениями-->
            <template v-slot:body.prepend="{}">
              <tr>
                <td v-for="(header, i) in headers" :key="i" class="text-center border-right">
                  <span v-if="header.value === 'user_price'" class="text--primary font-weight-bold"> {{avg.user_price.toFixed(1)}}</span>
                  <span v-if="header.value === 'stats.bot.ctr_register'" class="text--primary font-weight-bold"> {{avg.users_all.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_image'" class="text--primary font-weight-bold"> {{avg.images.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_500'" class="text--primary font-weight-bold"> {{avg.users_500.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_image_or_500'" class="text--primary font-weight-bold"> {{avg.users_image_or_500.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_half_of_500_or_image'" class="text--primary font-weight-bold"> {{avg.users_half_of_500_or_image.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_all_free_coins'" class="text--primary font-weight-bold"> {{avg.users_all_free_coins.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_paid_of_all_free'" class="text--primary font-weight-bold"> {{avg.users_paid_of_free.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_paid_of_users'" class="text--primary font-weight-bold"> {{avg.users_paid.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_paid'" class="text--primary font-weight-bold"> {{avg.users_paid_of_views.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.ctr_all_of_500_or_image'" class="text--primary font-weight-bold"> {{avg.users_all_of_500_or_image.toFixed(1)}}%</span>
                  <span v-if="header.value === 'stats.bot.users_avg_paid'" class="text--primary font-weight-bold"> {{avg.purchase.toFixed(1)}}</span>

                  <span v-if="header.value === 'stats.bot.users_all'" class="text--secondary font-weight-bold"> {{total.users_all.toLocaleString()}}</span>
                  <span v-if="header.value === 'stats.bot.users_image'" class="text--secondary font-weight-bold"> {{total.images.toLocaleString()}}</span>
                  <span v-if="header.value === 'stats.bot.users_500'" class="text--secondary font-weight-bold"> {{total.users_500.toLocaleString()}}</span>
                  <span v-if="header.value === 'stats.bot.users_image_or_500'" class="text--secondary font-weight-bold"> {{total.users_image_or_500.toLocaleString()}}</span>
                  <span v-if="header.value === 'stats.bot.users_half_coins'" class="text--secondary font-weight-bold"> {{total.users_half_coins.toLocaleString()}}</span>
                  <span v-if="header.value === 'stats.bot.users_all_free_coins'" class="text--secondary font-weight-bold"> {{total.users_all_free_coins.toLocaleString()}}</span>
                  <span v-if="header.value === 'stats.bot.users_paid'" class="text--secondary font-weight-bold"> {{total.users_paid.toLocaleString()}}</span>
                  <span v-if="header.value === 'stats.bot.money'" class="text--secondary font-weight-bold"> {{total.money.toLocaleString()}}</span>
                  <span v-if="header.value === 'stats.bot.tokens_cost'" class="text--secondary font-weight-bold"> {{total.tokens_cost.toFixed()}}$</span>
                </td>
              </tr>
            </template>
            <template v-slot:item.adChannelName="{item}">
              <span v-if="item.adChannel">
                {{ item.adChannel.name }}
              </span>
            </template> <!-- вместо id канала выводим название-->
            <template v-slot:item.adPost="{item}">
              <span v-if="item.adPost">
                {{ item.adPost.name }}
              </span>
            </template> <!-- вместо id поста выводим название-->
            <template v-slot:item.posted_at="{item}">
              {{ formatDate(item.posted_at) }}
            </template> <!-- форматируем дату-->
            <template v-slot:item.user_price="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor((item.price / item.stats.bot.users_all), avg.user_price, true)">{{(item.price / item.stats.bot.users_all).toFixed(2)}}</div>
            </template> <!-- user_price-->
            <template v-slot:item.stats.bot.ctr_register="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_register, avg.users_all)" @click="showChart('ctr_register')">{{item.stats.bot.ctr_register.toFixed(2)}}%</div>
            </template> <!-- ctr_register-->
            <template v-slot:item.stats.bot.ctr_image="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_image, avg.images)">{{item.stats.bot.ctr_image.toFixed(2)}}%</div>
            </template> <!-- ctr_images-->
            <template v-slot:item.stats.bot.ctr_500="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_500, avg.users_500)">{{item.stats.bot.ctr_500.toFixed(2)}}%</div>
            </template> <!-- ctr_500-->
            <template v-slot:item.stats.bot.ctr_image_or_500="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_image_or_500, avg.users_image_or_500)">{{item.stats.bot.ctr_image_or_500.toFixed(2)}}%</div>
            </template> <!-- ctr_image_or_500-->
            <template v-slot:item.stats.bot.ctr_half_of_500_or_image="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_half_of_500_or_image, avg.users_half_of_500_or_image)">{{item.stats.bot.ctr_half_of_500_or_image.toFixed(2)}}%</div>
            </template> <!-- ctr_half_of_500_or_image-->
<!--            <template v-slot:item.stats.bot.ctr_all_of_half="{item}">-->
<!--              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_all_of_half, avg.users_all_of_half)">{{item.stats.bot.ctr_all_of_half.toFixed(2)}}%</div>-->
<!--            </template> &lt;!&ndash; ctr_all_of_half&ndash;&gt;-->
            <template v-slot:item.stats.bot.ctr_all_of_500_or_image="{item}">
              <div v-if="item.stats && item.stats.bot && item.stats.bot.ctr_all_of_500_or_image" :class="getTextColor(item.stats.bot.ctr_all_of_500_or_image, avg.users_all_of_half)">{{item.stats.bot.ctr_all_of_500_or_image.toFixed(2)}}%</div>
            </template> <!-- ctr_all_of_half-->
            <template v-slot:item.stats.bot.ctr_all_free_coins="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_all_free_coins, avg.users_all_of_free)">{{item.stats.bot.ctr_all_free_coins.toFixed(2)}}%</div>
            </template> <!-- ctr_all_free_coins-->
            <template v-slot:item.stats.bot.ctr_paid_of_all_free="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_paid_of_all_free, avg.users_paid_of_free)">{{item.stats.bot.ctr_paid_of_all_free.toFixed(2)}}%</div>
            </template> <!-- ctr_paid_of_all_free-->
            <template v-slot:item.stats.bot.ctr_paid="{item}">
              <div v-if="item.stats && item.stats.bot" :class="getTextColor(item.stats.bot.ctr_paid, avg.users_paid_of_views)">{{item.stats.bot.ctr_paid.toFixed(2)}}%</div>
            </template> <!-- ctr_paid_of_all_free-->

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <bot-pub-chart-dialog :show="showChart" :data="chartData" :options="chartOptions"></bot-pub-chart-dialog>
  </v-container>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import store from "@/store";
import moment from "moment/moment";
import AdChannelSelector from "@/components/AdChannelSelector";
import AdPostSelector from "@/components/AdPostSelector";
import BotPubChartDialog from "@/views/ads/publications/BotPubChartDialog";

export default {
  name: "BotPublications",
  components:{
    AdPostSelector, AdChannelSelector, BotPubChartDialog
  },
  setup(props, {emit}) {
    const chartDialog = ref(false);
    const chartData = ref([]);
    const chartOptions = ref({});
    const allHeaders = ref([
      { text: 'ID', value: 'id', sortable: false, cellClass: 'sticky-column', class: 'sticky-column'},
      { text: 'Источник', value: 'adChannelName', cellClass: 'sticky-column', class: 'sticky-column', width: '170px' },
      { text: 'Пост', value: 'adPost', hint: ''},
      { text: 'Статус', value: 'status', hint: '' },
      { text: 'Цена', value: 'price', hint: '' },
      { text: 'Дата публикации', value: 'posted_at', hint: '' },
      { text: 'Охват', value: 'views', hint: '' },
      { text: 'Старт', value: 'stats.bot.users_all', hint: 'Кол-во юзеров, нажавших Старт', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Цена юз.', value: 'user_price'},
      { text: 'CTR', value: 'stats.bot.ctr_register', hint: 'Кол-во зарегистрированных юзеров / Охват * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Карт', value: 'stats.bot.users_image', hint: 'Кол-во юзеров, сгенерировавших >= 1 картинки', class: 'narrow-column', cellClass: 'border-right-dashed', align: 'center'},
      { text: '%', value: 'stats.bot.ctr_image', hint: 'Кол-во юзеров с картинкой / Кол-во юзеров * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: '500 т', value: 'stats.bot.users_500', hint: 'Кол-во юзеров, потративших 500 !!ТОКЕНОВ!!', class: 'narrow-column', cellClass: 'border-right-dashed', align: 'center'},
      { text: '%', value: 'stats.bot.ctr_500', hint: 'Кол-во юзеров потративших 500 ТОКЕНОВ / Кол-во юзеров * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Картинка или 500 т', value: 'stats.bot.users_image_or_500', hint: 'Кол-во юзеров, потративших 500 !!ТОКЕНОВ!! или сгенерировавших >= 1 картинки', class: 'narrow-column', cellClass: 'border-right-dashed', align: 'center'},
      { text: '%', value: 'stats.bot.ctr_image_or_500', hint: 'Кол-во юзеров с картинкой или потративших 500 ТОКЕНОВ / Кол-во юзеров * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      // { text: 'Половина коинов', value: 'stats.bot.users_half_coins', hint: 'Кол-во юзеров, потративших > половины от стартовых коинов', class: 'narrow-column', cellClass: 'border-right-dashed', align: 'center'},
      // { text: '%', value: 'stats.bot.ctr_half_of_500_or_image', hint: 'Кол-во потративших половину коинов / Кол-во потративших 500 или 1 картинку * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Все коины', value: 'stats.bot.users_all_free_coins', hint: 'Кол-во юзеров, у которых кончились !!КОИНЫ!!, включая купленные', class: 'narrow-column', cellClass: 'border-right-dashed', align: 'center'},
      // { text: '%', value: 'stats.bot.ctr_all_of_half', hint: 'Кол-во потративших все БЕСПЛАТНЫЕ коины / Кол-во потративших половину БЕСПЛАТНЫХ коинов * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: '%', value: 'stats.bot.ctr_all_of_500_or_image', hint: 'Кол-во потративших все БЕСПЛАТНЫЕ коины / Кол-во создавших картинку или 500 токенов * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Все к', value: 'stats.bot.ctr_all_free_coins', hint: 'Кол-во юзеров потративших все бесплатные КОИНЫ / Кол-во юзеров * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Купили', value: 'stats.bot.users_paid', hint: 'Кол-во юзеров, купивших что-нибудь', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Акт.', value: 'stats.bot.users_paid_active', hint: 'Кол-во юзеров, купивших что-нибудь и пользовавшихся ботом за последние 7 дней', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Опл от всех', value: 'stats.bot.ctr_paid_of_all_free', hint: 'Кол-во платящих юзеров / Кол-во юзеров потративших все бесплатные токены * 100', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Платят', value: 'stats.bot.ctr_paid_of_users', hint: 'Кол-во платящих юзеров / Кол-во юзеров * 100', class: 'narrow-column', cellClass: 'border-right-dashed', align: 'center'},
      { text: '‰', value: 'stats.bot.ctr_paid', hint: 'Кол-во платящих юзеров / Охват * 10 000', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Ср чек', value: 'stats.bot.users_avg_paid', hint: 'Средний чек', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Заработано', value: 'stats.bot.money', hint: 'Сумма оплат от юзеров, которые пришли по этой публикации', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Потрачено', value: 'stats.bot.tokens_cost', hint: 'Потрачено токенов / 1000 * 0.002', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
      { text: 'Приглашено', value: 'stats.bot.users_invited', hint: 'Кол-во юзеров, которые приглашены юзерами, которые пришли по этой публикации', class: 'narrow-column', cellClass: 'border-right', align: 'center'},
    ])
    const filters = ref({
      ad_post_id: null,
      ad_channel_id: null,
      dates: null,
      statsDate: null,
      adChannel: null,
      adPost: null,
      posted_at: null,
      users_all: null,
      users_image: null,
      users_500: null,
      users_image_or_500: null,
      users_half_coins: null,
      users_all_free_tokens: null,
      users_paid: null,
      users_avg_paid: null,
      money: null,
      from_bot: true,
      from_channel: true,
      to_bot: true,
      to_channel: true,
      status: 'Опубликован',
    })
    const headers = computed(()=>allHeaders.value)
    const allPublications = computed(()=>store.getters.botPublications(filters.value.statsDate))
    const publications = computed(()=>{
      let pubs = allPublications.value
      if (!filters.value.from_bot){
        pubs = pubs.filter(x=>x.adChannel && x.adChannel.is_bot)
      }
      if (!filters.value.from_channel){
        pubs = pubs.filter(x=>x.adChannel && !x.adChannel.is_bot)
      }
      if (!filters.value.to_bot){
        pubs = pubs.filter(x=>!x.bot_id)
      }
      if (!filters.value.to_channel){
        pubs = pubs.filter(x=>!x.channel_id)
      }
      if (filters.value.ad_channel_id){
        pubs = pubs.filter(x=>x.ad_channel_id===filters.value.ad_channel_id)
      }
      if (filters.value.ad_post_id){
        pubs = pubs.filter(x=>x.ad_post_id===filters.value.ad_post_id)
      }
      if (filters.value.status){
        pubs = pubs.filter(x=>x.status===filters.value.status)
      }
      if (filters.value.dates) {
        if (filters.value.dates.length===1) {
          pubs = pubs.filter(x=>moment(x.planned_at).isSame(filters.value.dates[0], 'day'))
        } else {
          filters.value.dates.sort((a, b) => new Date(a) - new Date(b))
          pubs = pubs.filter(x=>moment(x.planned_at).isBetween(filters.value.dates[0], filters.value.dates[1], 'day', '[]'))
        }
      }
      return pubs
    })
    const formatDate = date => date==='0000-00-00 00:00:00' ? 'Ещё не вышло' : moment(date).format('DD.MM.YY')

    const statuses = computed(()=>allPublications.value.map(x=>x.status).filter((v, i, a) => a.indexOf(v) === i))

    const total = computed(()=>{
      const pubs = publications.value.filter(x=>x.stats).filter(x=>x.stats.bot)
      return{
        users_all: pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0),
        images: pubs.reduce((sum, p)=>sum+p.stats.bot.users_image, 0),
        users_500: pubs.reduce((sum, p)=>sum+p.stats.bot.users_500, 0),
        users_image_or_500: pubs.reduce((sum, p)=>sum+p.stats.bot.users_image_or_500, 0),
        users_half_coins: pubs.reduce((sum, p)=>sum+p.stats.bot.users_half_coins, 0),
        users_all_free_coins: pubs.reduce((sum, p)=>sum+p.stats.bot.users_all_free_coins, 0),
        users_paid: pubs.reduce((sum, p)=>sum+p.stats.bot.users_paid, 0),
        money: pubs.reduce((sum, p)=>sum+p.stats.bot.money, 0),
        tokens_cost: pubs.reduce((sum, p)=>sum+p.stats.bot.tokens_cost, 0),
      }
    })

    const avg = computed(()=>{
      const pubs = publications.value.filter(x=>x.stats).filter(x=>x.stats.bot)
      return{
        users_all: pubs.filter(x=>x.views).reduce((sum, p)=>sum+p.stats.bot.users_all, 0)/pubs.filter(x=>x.views).reduce((sum, p)=>sum+p.views, 0)*100 || 0,
        user_price: pubs.reduce((sum, p)=>sum+p.price, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0) || 0,
        images: pubs.reduce((sum, p)=>sum+p.stats.bot.users_image, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0)*100,
        users_500: pubs.reduce((sum, p)=>sum+p.stats.bot.users_500, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0)*100,
        users_image_or_500: pubs.reduce((sum, p)=>sum+p.stats.bot.users_image_or_500, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0)*100,
        users_half_coins: pubs.reduce((sum, p)=>sum+p.stats.bot.users_half_coins, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0)*100,
        users_half_of_500_or_image: pubs.reduce((sum, p)=>sum+p.stats.bot.users_half_coins, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_image_or_500, 0)*100,
        users_all_free_coins: pubs.reduce((sum, p)=>sum+p.stats.bot.users_all_free_coins, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0)*100,
        users_paid_of_free: pubs.reduce((sum, p)=>sum+p.stats.bot.users_paid, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all_free_coins, 0)*100,
        users_paid: pubs.reduce((sum, p)=>sum+p.stats.bot.users_paid, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0)*100,
        users_paid_of_views: pubs.filter(x=>x.views).reduce((sum, p)=>sum+p.stats.bot.users_paid, 0)/pubs.filter(x=>x.views).reduce((sum, p)=>sum+p.views, 0)*10000,
        users_all_of_half: pubs.reduce((sum, p)=>sum+p.stats.bot.users_all_free_coins, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_half_coins, 0)*100,
        users_all_of_500_or_image: pubs.reduce((sum, p)=>sum+p.stats.bot.users_all_free_coins, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_image_or_500, 0)*100,
        users_all_of_free: pubs.reduce((sum, p)=>sum+p.stats.bot.users_all_free_coins, 0)/pubs.reduce((sum, p)=>sum+p.stats.bot.users_all, 0)*100,
        purchase: pubs.filter(q=>q.stats.bot.money>0).reduce((sum, p)=>sum+p.stats.bot.money, 0)/pubs.filter(q=>q.stats.bot.money>0).reduce((sum, p)=>sum+p.stats.bot.users_paid, 0),
      }
    })

    const getTextColor = (val, avg, rev = false) => {
      if (rev){
        if(val>avg*1.2) return 'red-text'
        if(val<avg*0.8) return 'green-text'
        return ''
      }
      //если на 10% больше среднего, то зеленый
      if(val>avg*1.2) return 'green-text'
      //если на 10% меньше среднего, то красный
      if(val<avg*0.8) return 'red-text'
      return ''
    }

    const showChart = (param, pub_id) =>{
      return false
    }

    return {
      chartDialog, chartData, chartOptions,
      allHeaders, headers,
      publications,
      statuses,
      filters,
      avg, total,
      formatDate, getTextColor, showChart,
    }
  }
}
</script>

<style scoped>
.v-data-table >>> .sticky-column {
  position: sticky !important;
  left: 0 !important;
  background-color: white !important;
  z-index: 1 !important;
  font-size: 14px;
}

.v-data-table >>> .narrow-column {
  max-width: 75px;
}

.v-data-table >>> tbody td {
  /*border: 1px solid #ddd !important;*/
  padding: 0 5px !important;
  text-align: start;
}
.v-data-table >>> .v-data-table__wrapper > table > thead > tr > th,
.v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 2px;
}

/deep/ .border-right {
  border-right: 2px solid #ddd !important;
}
/deep/ .border-right-dashed {
  border-right: 1px dashed #ddd !important;
}

.green-text {
  color: green;
}

.red-text {
  color: #ff5050;
}
</style>
